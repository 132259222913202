<template>
  <div
    v-if="grid.md && video.id"
    class="video-box z-30 absolute left-app right-app width-md pointer-events-none fade-in animation-delay-100"
    :class="{ 'width-lg': grid.xxl }"
  >
    <div class="flex justify-end">
      <div
        class="video-box__box shadow-2xl rounded-lg border-2 border-grey-100 pointer-events-auto"
        :class="{ 'video-box__box--hover': hover }"
        data-rellax-speed="1"
        ref="videoBox"
      >
        <div
          class="rounded-lg overflow-hidden h-full w-full relative bg-white bg-loading"
        >
          <div class="video-box__box__image w-full fade-up animation-delay-100">
            <img
              :src="src"
              alt="video box image"
              class="object-cover w-full h-full"
              data-rellax-speed="-0.5"
              ref="videoBoxImage"
            />
          </div>
          <div class="overlay absolute inset-0"></div>
          <div
            class="video-box__box__button w-full h-full absolute inset-0 flex justify-center items-center z-10 backface-hidden"
          >
            <ButtonRound
              @hovered="buttonHover"
              @click="emitButtonClick"
              icon="videocamera"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import ButtonRound from "@/components/Buttons/ButtonRound.vue";
import { useParallax } from "@/composables/useParallax";
import { CONSTANTS } from "@/util/constants";

export default {
  name: "LondoVideoBox",
  components: { ButtonRound },
  props: {
    video: {
      type: [Boolean, Object],
      default: false,
    },
  },
  setup(props, { emit }) {
    const hover = ref(false);
    const videoBox = ref(null);
    const videoBoxImage = ref(null);
    const parallaxRefs = [videoBox, videoBoxImage];

    const src = computed(() => {
      return props.video.image.sizes
        ? props.video.image.sizes.xs
        : CONSTANTS.images.fallback.videoBox;
    });

    const { createParallax } = useParallax();

    const buttonHover = (val) => {
      hover.value = val;
    };

    const emitButtonClick = () => {
      emit("video-button-click");
    };

    onMounted(() => {
      createParallax(parallaxRefs);
    });

    return {
      hover,
      src,
      buttonHover,
      emitButtonClick,
      videoBox,
      videoBoxImage,
    };
  },
};
</script>

<style scoped lang="scss">
.single-page {
  .video-box {
    margin-top: -16px;
    transform: translate(0, -83%);
  }
}
.single-page-tabs {
  .video-box {
    transform: translate(0, -72%);
  }
}
.single-page-thumbs {
  .video-box {
    padding-right: 24px;
  }
}
.video-box {
  transform: translate(0, -80%);
  &__box {
    width: 250px;
    height: 154px;
    box-shadow: 0px 14px 50px 11px rgba(0, 0, 0, 0.18);
    transition: 0.2s box-shadow ease-in;
    :deep(.btn-round) {
      i {
        font-size: 1.6rem;
        padding: 0 0 0.1em 0.1em;
      }
    }
    .overlay {
      @include overlay-videobox;
    }
    &__image {
      height: calc(100% + 40px);
      margin-top: -40px;
      img {
        filter: grayscale(0.5);
        max-width: 434px;
        transition: 0.2s filter ease-in;
      }
    }
    &.video-box__box--hover {
      box-shadow: 0px 5px 60px 11px rgba(0, 0, 0, 0.26);
      transition: 0.2s box-shadow ease-out;
      img {
        filter: grayscale(0);
        transition: 0.2s filter ease-out;
      }
    }
  }
}
@screen lg {
  .video-box {
    transform: translate(0, -50%);
    &__box {
      width: 315px;
      height: 194px;
    }
  }
  .single-page {
    .video-box {
      transform: translate(0, -86%);
    }
    &.single-page-tabs {
      .video-box {
        //transform: translate(0, -64%);
      }
    }
  }
}
@screen xl {
  .video-box {
    transform: translate(0, -50%);
  }
  .single-page-thumbs {
    .video-box {
      padding-right: 0;
    }
  }
}
@screen xxl {
  .video-box {
    transform: translate(0, -50%);
    &__box {
      width: 360px;
      height: 222px;
    }
  }
  .single-page {
    .video-box {
      transform: translate(0, -86%);
    }
    &.single-page-tabs {
      .video-box {
      }
    }
  }
}
</style>
