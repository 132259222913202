<template>
</template>

<script>
  // IF THERE IS A BETTER WAY TO DO THIS, PLEASE DO IT
  import("lightgallery.js");

  export default {
    name: "Lightgallery",
    async setup() {
      import("lg-video.js");
    }
  }
</script>
