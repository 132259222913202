import { ref, computed, onBeforeUnmount } from 'vue';
import { useDevice } from '@/composables/useDevice';

const defaultVideoOptions = {
  addClass: "video-gallery",
  videoMaxWidth: "100%",
  vimeoPlayerParams: {
    counter: false,
    byline: false,
    color: "3798fb",
    portrait: false,
    title: false,
    transparent: true,
    controls: true,
  },
};

export function useLightGalleryVideo() {
  const videoOptions = ref(defaultVideoOptions);
  const videoEl = ref(null);
  const videoPlayerId = ref(false);
  const device = useDevice();

  const dynamicVimeoPlayerParams = computed(() => {
    return {
      autoplay: !device.mobile,
      playsinline: !device.mobile,
    };
  });

  const openVideoPlayer = (videoGalleryRef, fullScreenVideoRefTrigger) => {
    videoEl.value = videoEl.value
      ? videoEl.value
      : videoGalleryRef;

    // add dynamic vimeo options
    videoOptions.value.vimeoPlayerParams = {
      ...videoOptions.value.vimeoPlayerParams,
      ...dynamicVimeoPlayerParams.value,
    };

    lightGallery(videoEl.value, {
      ...videoOptions.value,
    });

    videoPlayerId.value = videoEl.value.getAttribute("lg-uid");

    // have to sim el click as lightgallery dynamic vimeo unsupported
    fullScreenVideoRefTrigger.click();
  };

  onBeforeUnmount(() => {
    if (videoPlayerId.value) {
      console.log(
        "%c%s",
        "color:pink;",
        "destroy video gallery:",
        videoPlayerId.value,
      );
      window.lgData[videoPlayerId.value].destroy(true);
    }
  });

  return { openVideoPlayer };
};

