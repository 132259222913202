<!-- eslint-disable max-len -->
<template>
  <div
    v-if="!grid.md"
    class="banner__actions z-10 absolute right-0 top-0 -translate-y-full transform fade-in animation-delay-100"
  >
    <div
      class="flex justify-end mr-6 xs:mb-1"
      data-rellax-speed="-2"
      ref="mediaActions"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useParallax } from "@/composables/useParallax";

export default {
  name: "LondoMediaActions",
  setup() {
    const mediaActions = ref(null);
    const { createParallax } = useParallax();

    onMounted(() => {
      createParallax([mediaActions]);
    });

    return {
      mediaActions,
    };
  },
};
</script>

<style scoped lang="scss">
.banner__actions {
}
.single-page {
  .banner__actions {
    margin-top: -16px;
    .btn-default.btn-inverse {
      min-width: 48px;
      height: 48px;
    }
  }
}
.index-page {
  .btn-default.btn-icon {
    padding-left: 1.3rem;
    min-height: 46px;
  }
}
</style>
