<template>
  <div id="video-gallery" class="hidden" ref="videoGalleryRef">
    <a :href="videoSrc" ref="triggerRef"></a>
  </div>
</template>

<script>
import { computed, ref } from "vue";

export default {
  name: "FullScreenVideo",
  props: {
    video: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const videoSrc = computed(() => `https://vimeo.com/${props.video.id}`);
    const videoGalleryRef = ref(null);
    const triggerRef = ref(null);

    context.expose({
      videoGalleryRef,
      triggerRef,
    });

    return {
      videoSrc,
      videoGalleryRef,
      triggerRef,
    };
  },
};
</script>

<style lang="scss">
// hide counter for full screen video, lg counter option doesn't work
.video-gallery {
  #lg-counter {
    display: none;
  }
}
</style>
